.sidebarArea {
  flex-shrink: 0;
  width: 17vw;
  background-color: white !important;
  color: $text-color-black;
  position: fixed;
  top: $navHeight;
  height: calc(100vh - ($navHeight));
  z-index: 1;
  padding: 0px 20px 0px 24px;
  transition: transform 0.3s ease, opacity 0.3s ease;

  // transition: width 0.3s ease;
  .subMenuIcon {
    float: inline-end;
  }

  .sidenav-bg:hover {
    background-color: $sidebar-menu-bg;
    // color: $text-color !important;
    font-family: $font-family-poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }

  // .sidenav-bg .active {
  //   background-color: $danger;
  //   color: $light !important;
  //   font-family: $font-family-poppins;
  //   font-size: 14px;
  //   font-weight: 700;
  //   line-height: 16.94px;
  //   text-align: left;
  // }

  .sidenav-bg {
    background-color: $white;
    // color: $text-color;
    color: $text-color-black;
    font-family: $font-family-poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }

  .sidebarNav .sidenav-bg a {
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
    color: $text-color-black;
    display: flex;
    flex-wrap: nowrap;
    // flex-wrap: wrap;
    align-items: center;
  }


  .accordion-body a {
    height: 65px;
  }

  .accordion-header>button {
    height: 65px;
  }

  .accordion-item {
    border-radius: 0px;
  }

  // .sidenav-bg .active i {
  //   color: $light !important;
  // }

  // .sidebarNav .sidenav-bg .sub_menu a .sub_menu_icon {
  //   // color: $text-color !important;
  //   color: $text-color-black;
  // }

  // .sidenav-bg .sub_menu .active,
  // .sidenav-bg .sub_menu .active .sub_menu_icon {
  //   color: $danger !important;
  // }

  .sidebarNav .sidenav-bg a .subMenuIcon {
    position: relative;
  }

  .sidebarNav .sidenav-bg a span {
    // bottom: 5px;
    position: relative;
  }
}

.sidebarItem {
  border-radius: 4px;
}

.sub_menu {
  background-color: white !important;
}

ul.sub_menu li a.active,
ul.sub_menu li a:hover {
  background: $danger;
  color: #FFFFFF !important;
}


.sub_menu_icon {
  margin-left: 25px;
}

.sidebar_icon {
  font-size: 20px;
}



/*
Use : This is Replce the default padding py-2 of sidebar item
Author : Yash Darshankar
Date:18-06-2024
*/
.sidebarItem {
  padding: 0.7rem 0rem !important;
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;

}

.SidebarMainItemText {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.SidebarMainItemIcon {
  font-size: 30px;
}