.pageWrapper {
  min-height: 100vh;
  background-color: white;
}

a {
  text-decoration: none !important;
}

.contentArea {
  flex-grow: 1;
  max-width: 83vw;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 17%;
  margin-top: 83px;
  border-radius: 24px;
  background-color: #F8F9F9;
  padding: 8px 24px;
}

.header_urs_label {
  margin-left: 12px !important;
}



.card {
  margin-top: 8px;
}



.web-header:after {
  background-color: $light;
  display: block;
  clear: both;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;

  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}

.button-group .btn {
  margin: 3px;
}

.renderTable>thead>tr:last-child {
  border-bottom: 2px solid;
  padding: 5rem;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.nav-tabs>.nav-item {
  display: flex;
  gap: 35px;
  flex: none;
  // margin-right: 10px;
  margin-right: 7px;
}

.nav-tabs .nav-link {
  border-radius: 0;
  background-color: #E9ECF0;

  color: $text-color-black;

  padding: 10px 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 51px;

  min-width: 194px !important;
  height: 48px;
  border-radius: 8px 8px 0px 0px;

  font-family: $font-family-exo;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.26px;
  text-align: left;

  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    color: $white;
    background-color: $text-color-black;

    border-bottom: 3px solid $text-color-black;
  }
}

.text-secondary {
  background-color: $text-color;

  --bs-text-opacity: $text-color;
}