.navbar-expand .navbar-nav {
    background-color: $white;
}

// .navbar {
//   position: fixed;
//   width: 100%;
//   height: $navHeight;
//   top: 0;
//   // box-shadow: 4px 4px $box-shadow-color;
//   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05)

// }
.navbar {
    position: fixed;
    width: 100%;
    height: 80px;
    top: 0;
    background: $white;
}

.navbar-with-border {
    box-shadow: 0px 4px 8px 0px rgba(95, 95, 95, 0.1019607843);

}

.navbar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18%;
    /* Exclude the first 18% */
    height: 100%;
    background: white;
    /* Match the background color */
    z-index: 1;
    /* Ensure it sits above the .navbar */
    box-shadow: none;
    /* Remove any shadow on the pseudo-element itself */
}



.navbar-brand {
    margin-right: 0%;
}

#navbarDropdown {
    background-color: $white;
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
    gap: 4px;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
    padding: 0px;
}

.notification-section .dropdown-menu.show {
    min-width: 300px;
}