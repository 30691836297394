@media (max-width: 1480px) {
  li.sidenav-bg.nav-item a {
    padding-right: 8px !important;
  }

  li.sidenav-bg.nav-item .ms-3 {
    margin-left: 10px !important;
  }

  li.sidenav-bg.nav-item .sub_menu_icon {
    margin-left: 30px;
  }

  .navbar::before {
    width: 0% !important;
  }
}

@media (max-width: 1416px) {
  .contentArea {
    flex-grow: 1;

    max-width: 100vw;

    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 0%;
  }

  .sidebarArea {
    width: 250px;
  }


  .tcrcLogo {
    width: 250px !important;
  }

  .header_list_icon {
    display: block !important;
    padding-right: 12px;
  }

  .branchDetailsSection {
    margin: 0px !important;
  }

  .tcrcLogoHeader {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .customInput>div {
    width: auto !important;
  }

  .popupWidthAdjustment {
    width: 43% !important;
  }

  .popupWidthAdjustmentInward {
    width: 60% !important;
  }
}

@media screen and (max-width: 1180px) {
  .Scrollable {
    overflow-x: auto;
  }

  .renderList_table {
    overflow: auto;
  }
}

@media (max-width: 992px) {
  .right_header_icons {
    display: flex;
    /*
      Author : Yash Darshankar
      Date : 30/01/2025
      Description: Removing gap 0px
  */
  }

  .header_table_box2 {
    height: auto;
  }

  .tcrcLogo {
    width: 250px !important;
  }

  .header_urs_label {
    margin-left: 12px !important;
  }

  .header_label {
    margin-left: 0px !important;
  }

  .jrf_container_btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
  }

  .header_table_status_item {
    width: 220px;
  }
}

@media (max-width: 854px) {
  .popupWidthAdjustment {
    width: 60% !important;
  }

  .card_header_btns>button {
    height: auto;
  }
}

@media (max-width: 800px) {
  .list_title {
    background-color: $backgroundColorScreen;
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
}

@media (max-width: 768px) {
  .right_header_icons {
    display: flex;
    /*
  Author : Yash Darshankar
  Date : 30/01/2025
Description: Removing gap 0px
*/
  }

  .header_table_status_item {
    width: 160px;
  }

  .renderList_sub_table_heading {
    gap: 16px;
  }

  .card_header_btns>button {
    width: auto;
    height: auto;
    border-radius: 8px 8px 0px 0px;
    background: #E9ECF0;
    color: $text-color-black;
    font-family: $font-family-exo;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    padding: 5px;
    text-align: left;
    border: none;
    cursor: default !important;
  }

  .extra-text {
    display: none;
  }

  .popupInwardModal {
    padding: 0px 10px;
  }

  .tileHeaderNav {
    gap: 8px;
  }
}

@media (max-width: 700px) {

  .renderTable th,
  td {
    min-width: 120px;
    padding: 0px 4px !important;
  }
}

@media (max-width: 600px) {

  //Full Layout Page
  .contentArea {
    flex-grow: 1;

    max-width: 100vw;

    overflow-x: hidden;
    overflow-y: auto;
  }

  .loginBtnContainer {
    width: 100% !important;
    justify-content: center;
  }

  .loginBtnContainer button {
    width: 100% !important;
    min-width: 260px;
  }

  .popupPosition {
    display: block;
    position: relative;
  }

  .DocumentContainer {
    display: flex;

    justify-content: center;
    width: 100%;
  }

  .renderList_sub_table_heading {
    width: 100% !important;
    flex-wrap: wrap;
    gap: 4px;
  }

  .header_location i {
    font-size: 16px;
    color: white;
  }

  .popupWidthAdjustment {
    width: 85% !important;
  }

  .popupWidthAdjustmentInward {
    width: 80% !important;
  }

  .renderList_table_heading {
    flex-direction: column;
  }

  .Heading_doc {
    flex-direction: row;
  }

  .main_form {
    padding: 16px 0vw;
  }

  .main_form .section_heading {
    margin-left: 0px;
  }

  .pagination-container {
    display: flex;
    flex-direction: column;
  }
}


@media (max-width: 569px) {
  .header_table_status_item {
    width: 132px;
  }
}

@media (max-width: 500px) {
  .form-group label {
    width: 75% !important;

    justify-content: left !important;
    margin-bottom: 8px;
    text-align: justify;
  }

  .form-group .radioOption label,
  .draftSurveyTd .form-group label {
    margin-bottom: 0px;
  }

  .my-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header_table_box1,
  .header_table_box2 {
    display: none !important;
  }

  .status-dropdown {
    display: flex;
    width: 89vw;
    justify-content: center;
    align-items: center;
  }

  .header_icon {
    display: none;
  }

  .headerIconLock {
    display: none;
  }

  .profileImgBg {
    display: none;
  }

  .plus-min-container {
    width: 75% !important;
  }

  .header_location {
    background: #E11D07;
    border-radius: 0 12px 0 12px;
    color: #f4f4f4 !important;
    font-family: Inter, Arial, sans-serif;
    font-size: 8px !important;
    font-weight: 500 !important;
    height: 16px;
    line-height: 17.52px !important;
    padding: 9px 14px;
    text-align: left !important;
    width: 100%;
  }


  .header_location>i {
    display: none;
  }

  .header_profile_2 {
    color: $text-color;
    cursor: pointer;
    font-size: 25px !important;
    display: block;
  }

  .header_profile_2>.dropdown-toggle {
    color: $text-color;
    cursor: pointer;
    font-size: 25px !important;
    display: block;
  }

  .header_urs_and_branch_label {
    display: none !important;
  }

  .mobile_logo {
    margin: 4px 0px;
    display: block;
  }

  .radioOptions {
    width: 75%;
  }

  .navbar {
    height: 50px;
  }

  .pageWrapper {
    background-color: #F8F9F9;
  }
}

@media (max-width: 426px) {
  .formSearch>input {
    width: 90vw !important;
    height: 36px !important;
  }

  .jrf_container_btns {
    gap: 12px;

  }

  .previewCommercialCertificateHeading {
    margin-left: 32px;
  }

}

@media (max-width: 420px) {
  .popupWidthAdjustment {
    width: 98% !important;
    padding: $select-height 16px !important;
    margin-right: 1%;
  }

  .rowWidthAdjust {
    width: auto !important;
  }

}

@media (max-width: 376px) {
  .previous_next_btns {
    padding: 10px 0px;
    gap: 4px;
  }

  .previous_next_btns>button {
    background: #ffffff;
    border: 1px solid #616161;
    color: #616161;
    border-radius: 2px;
    height: 36px;
    padding: 5px;
  }
}