@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap");
$font-family-sans-serif: "Inter", sans-serif;


$primary: #e5e5e5;
$info: #b0b6b7;
$danger: #E11D07;
$success: #2885a7;
$warning: #ffbc34;
$dark: #202020;
$sidebar-menu-bg: #ffffff;
$light: #eaf2fb;
$secondary: #faf6f6;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "light-primary": #d6e4f3,
  "light-success": #d5f3f2,
  "light-info": #d3edfa,
  "light-warning": #f8ecdc,
  "light-danger": #f8dddd,
);

$white: #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f2f7f8 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #757e85 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1e2a35 !default;
$text-color-black: #000 !default;

$min-contrast-ratio: 2;
$font-weight-bold: 500 !default;
$body-bg: $gray-200 !default;
$body-color: $gray-900 !default;
$gradient: linear-gradient(-90deg, rgba($white, 0.5), rgba($white, 0)) !default;

$border-radius: 0.3rem !default;
$box-shadow: 0 0.5rem 1rem rgba($text-color-black, 0.05);
$box-shadow-color: rgba(95, 95, 95, 0.1019607843);
$headings-font-weight: 400 !default;

$card-border-width: 0 !default;
$card-box-shadow: $box-shadow !default;
$grid-gutter-width: 1.85rem !default;

$card-bg: $white;
$input-bg: $white !default;
$list-group-bg: $white !default;
$table-bg: $white !default;
$dropdown-bg: $white !default;
$btn-focus-width: 0px;

$sidebarWidth: 17vw;
$sidebarColor: $white;



// UI CSS from 01-10-2024
$navHeight: 80px;
$select-height: 36px;

$font-family-poppins: Poppins, sans-serif;
$font-family-exo: Exo, Poppins, sans-serif;

// $text-color: #9b9b9b;
$text-color: #616161;
// $text-color-dark: #9b9b9b;
$text-color-dark: #666666;
$text-color-black: #0d1016;
// $text-color-black: #151B25;
$disabled-color-bg: #F1F1F1;
// $form-label-color: #9b9b9b;
$form-label-color: #686464;
$form-label-border: #D9D9D9;

$footerHeight: 40px;
$backgroundColorScreen: #F8F9F9;
$border-bottom-color: #151515;
// $alternate-tr: #ffffff;
$alternate-tr: #f3f3f3;
// $alternate-tr: #c8c8c8;
